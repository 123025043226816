import React from 'react';
import { observer } from 'mobx-react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { isEqual } from 'lodash';
import copy from 'copy-to-clipboard';

import store from '@stores';
import { STORAGE_TYPES, ENVIRONMENTS } from '@constants';
import { withStore } from '@stores/withStore';
import StorageManager from '@utils/StorageManager';
import ToolForm from './components/ToolForm/ToolForm';
import CommitForm from './components/CommitForm/CommitForm';
import NotesEditor from './components/NotesEditor/NotesEditor';
import LoaderButton from '@components/LoaderButton/LoaderButton';
import { MergeCodeTextArea } from '@components/ToolComponents/simpleUIs/JsonEditor';
import Button from '@components/Button/Button';
import variables from '@styles/variables';

import './JsonExtensionUI.scss';

interface JsonExtensionUIProps {
  store: typeof store;
  feedCode: string;
}

const localStorage = new StorageManager(STORAGE_TYPES.LOCAL, 'jsonExtension');

interface JsonExtensionUIState {
  commitState: 'initial' | 'pending' | 'succeed' | 'error';
  commitInitTimestamp: Date; // will hold a timestamp to help manage race conditions
}

class JsonExtensionUI extends React.Component<JsonExtensionUIProps, JsonExtensionUIState> {

  constructor(props) {
    super(props);
    this.state = {
      commitState: 'initial',
      commitInitTimestamp: new Date(),
    };
  }

  copyToClipboard = () => {
    const { jsonExtension } = this.props.store.pages.gtfs;

    const toolValueByToolName: object = this.getActiveToolValueByToolName();
    const stringifiedJsonExtension = jsonExtension.getStringifiedJsonExtension(toolValueByToolName);

    copy(stringifiedJsonExtension);
  }

  componentWillUnmount() {
    if (this.hideModal) {
      this.hideModal();
    }
  }

  // --------------------------- //
  //     RENDERING FUNCTIONS     //
  // --------------------------- //

  removeToolFromJsonExtension = (tool) => {
    const { jsonExtension } = this.props.store.pages.gtfs;
    jsonExtension.removeTool(tool);
  }

  updateJsonExtensionInLocalStorage = () => {
    const { jsonExtension, currentEnvironment } = this.props.store.pages.gtfs;
    const { feedCode } = this.props;

    const toolValueByToolName: object = this.getActiveToolValueByToolName();
    const jsonExtensionObject = jsonExtension.getJsonExtension(toolValueByToolName);

    localStorage.set(`${currentEnvironment}-${feedCode}`, jsonExtensionObject);
  }

  toolFormRefsByToolName = {};
  getToolFormRefs = () : any[] => {
    return Object.values(this.toolFormRefsByToolName)
      .filter(toolFormRef => !!toolFormRef);
  }

  getActiveToolValueByToolName = () => {
    const toolFormRefs = this.getToolFormRefs();
    return toolFormRefs.reduce((acc, ref: any) => {
      const value = ref && ref.state && ref.state.value;

      if (value) {
        return Object.assign(acc, value);
      }

      return acc;
    }, {});
  }

  getActiveToolValueWithToolName = (toolName: string) => {
    const toolFormRefs = this.getToolFormRefs();
    for (const ref of toolFormRefs) {
      if (ref && ref.props.tool.name === toolName) {
        return (ref && ref.state && ref.state.value[toolName]);
      }
    }
  }

  renderNoteEditor = (toolName: string, shouldRenderUnchangedTool: boolean = true) => {
    const { feedCode, store } = this.props;
    const { jsonExtension } = store.pages.gtfs;

    const toolValueWithToolName = this.getActiveToolValueWithToolName(toolName);
    const jsonExtensionValue = jsonExtension.getValueOfToolNameOfFeedCode(feedCode, toolName);

    const isToolActive = jsonExtension.isToolSelected(toolName);
    const isToolDeactivated = jsonExtension.isToolDeselected(feedCode, toolName);
    const toolHasChanged = !isEqual(toolValueWithToolName, jsonExtensionValue);

    if (!shouldRenderUnchangedTool && !toolHasChanged) {
      return null;
    }

    const noteOfToolOfFeedCode = jsonExtension.getNotesOfFeedCodeAndToolName(feedCode, toolName);

    const onChange = (note) => {
      jsonExtension.setNoteOfToolOfFeedCode(feedCode, toolName, note);
    };

    return (
      <NotesEditor
        hasChanged={toolHasChanged}
        isActive={isToolActive || isToolDeactivated}
        title={toolName}
        initialValue={noteOfToolOfFeedCode}
        onChange={onChange}
      />
    );
  }

  hideModal: any;
  openToolInfoModal = (toolName) => {
    const { modals } = this.props.store;

    const editor = this.renderNoteEditor(toolName);

    this.hideModal = modals.showModal({
      title: toolName,
      component: (
        <div>
          {editor}
          <LoaderButton value="save" onClickWithStatus={this.handleSaveNotes}/>
        </div>
      ),
    });
  }

  showDiffView = (toolName, currentValue, initialValue) => {
    const { modals } = this.props.store;

    this.hideModal = modals.showModal({
      title: `What has changed on ${toolName}`,
      component: (
        <div style={{ width: '90vw' }}>
          <MergeCodeTextArea
            value={initialValue}
            compareWith={currentValue}
          />
        </div>
      ),
    });
  }

  handleSaveJsonExtension = async (commitEnv: ENVIRONMENTS, commitTitle: string) => {
    const { feedCode } = this.props;
    const { jsonExtension } = this.props.store.pages.gtfs;

    const toolValueByToolName = this.getActiveToolValueByToolName();

    const config = {
      feedCode,
      commitTitle,
      commitEnv,
      toolValueByToolName,
    };

    const succeed = await jsonExtension.commitJsonExtension(config);
    return succeed;
  }

  handleSaveNotes = async () => {
    const { feedCode } = this.props;
    const { jsonExtension } = this.props.store.pages.gtfs;

    const succeed = await jsonExtension.commitNotes(feedCode);
    return succeed;
  }

  handleShowAllNotes = (buttonType: string) => {
    const { feedCode } = this.props;
    const { modals, pages } = this.props.store;
    const { jsonExtension } = pages.gtfs;

    const modalConfigs = [];
    const isUploadScreen = buttonType === 'upload';

    let jsonExtHasChanged = false;
    if (isUploadScreen) {
      const selectedToolNames = [...jsonExtension.selectedToolsNamesSet];
      const deselectedToolNames = [...jsonExtension.deselectedToolsNamesSet(feedCode)];

      const editors = selectedToolNames.concat(deselectedToolNames)
        .map((toolName) => {
          const toolData = this.toolFormRefsByToolName[toolName];
          const noteEditor = this.renderNoteEditor(toolName, false);
          if (!noteEditor) {
            return null;
          }

          return (
            <div className="review-modal-wrapper" key={toolName}>
              {noteEditor}
              {toolData && <div style={{ width: '100%' }}>
                <MergeCodeTextArea
                  value={toolData.state.initialValue}
                  compareWith={toolData.state.value}
                />
              </div>}
            </div>
          );
        })
        .filter(component => !!component);

      jsonExtHasChanged = editors.length > 0;

      modalConfigs.push({
        title: '1. Review Notes',
        component: (
          <div>
            {editors}
            {isUploadScreen ? null : <LoaderButton value="save" disable={jsonExtHasChanged} onClickWithStatus={this.handleSaveNotes}/>/* tslint:disable-line max-line-length */}
          </div>
        ),
      });

      modalConfigs.push({
        title:'2. Add Title and Upload',
        component: (
          <CommitForm
            disable={!jsonExtHasChanged}
            feedCode={feedCode}
            handleSubmit={this.handleSaveJsonExtension}
          />
        ),
      });
    }

    this.hideModal = modals.showModals(modalConfigs);
  }

  renderJsonExtension = (tool, index) => {
    const registerRef = (ref) => {
      this.toolFormRefsByToolName[tool.name] = ref;
    };

    return (
      <CSSTransition key={`tool-${tool.name}`} timeout={500} classNames="transition">
        <ToolForm
          ref={registerRef}
          remove={this.removeToolFromJsonExtension}
          tool={tool}
          showToolInfo={this.openToolInfoModal}
          showDiffView={this.showDiffView}
          updateJsonExtensionInLocalStorage={this.updateJsonExtensionInLocalStorage}
        />
      </CSSTransition>
    );
  }

  renderJsonExtensionSteps = (selectedToolList) => {
    const { jsonExtension: { steps } } = this.props.store.pages.gtfs;

    const toolsWithStep = selectedToolList.reduce((acc, tool) => {
      let stepName;
      steps.forEach((step) => {
        step.tools.forEach((stepTool) => {
          if (stepTool.index === tool.index) {
            stepName = stepTool.step;
          }
        });
      });

      const stepIndex = acc.findIndex(([step_name, _]) => step_name === stepName);
      const stepTools = stepIndex !== -1 ? acc[stepIndex][1] : [];
      stepTools.push(tool);

      if (stepIndex !== -1) {
        acc[stepIndex] = [stepName, stepTools];
      } else {
        acc.push([stepName, stepTools]);
      }

      return acc;
    }, []);

    return toolsWithStep.map(([stepName, tools]) => {
      return (
        <TransitionGroup key={stepName} component={null} appear={true} enter={true} exit={true}>
          <div className="tool-config-container">
            <div className="tool-config-wrapper">
              <div className="selected-tool-title" style={{ color: variables.transitdarkgreen, marginBottom: '15px' }}>
                {stepName.toUpperCase()}
              </div>
              {tools.map(this.renderJsonExtension)}
            </div>
          </div>
        </TransitionGroup>
      );
    });
  }

  render() {
    const { selectedToolList } = this.props.store.pages.gtfs.jsonExtension;

    return (
      <div className="tools-window-content tools-list-display col col-xl-8">
        <div className="menu-title">json extension</div>
        <div className="selected-tools">
          {this.renderJsonExtensionSteps(selectedToolList)}
          <div className="save-button-wrapper">
            <Button data="notes" className="notes" value="See All Notes" onClick={this.handleShowAllNotes}/>
            <Button className="copy" value="Copy to Clipboard" onClick={this.copyToClipboard}/>
            <Button data="upload" value="Upload Changes" onClick={this.handleShowAllNotes}/>
          </div>
        </div>
      </div>
    );
  }
}

export default withStore<JsonExtensionUIProps>(observer(JsonExtensionUI));
