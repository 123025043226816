import Papa from 'papaparse';
import React from 'react';

import store from '@stores';
import { withStore } from '@stores/withStore';
import SearchGtfsTableInput, { ColumnsSearchConfig } from './components/SearchGtfsTableInput';
import CsvDropFileZone from './components/CsvDropFileZone';
import CsvTableView from './components/CsvTableView';
import { CsvFileSystemButton, CsvExplorerFullScreenButton } from './components/CsvIconButtons';
import SelectGtfsFileDropdowns from './components/SelectGtfsFileDropdowns';
import CsvSortedView from './components/CsvSortedView';
import CsvFilterView from './components/CsvFilteredView';
import CsvTable from './lib/CsvTable';

import 'react-tabs/style/react-tabs.css';
import 'react-virtualized/styles.css';
import './CsvExplorerPage.scss';

interface CsvExplorerViewProps {
  store: typeof store;
  feed: any;
  csvConfig: CsvConfig;
  onCsvConfigChange(csvConfig: CsvConfig) : void;
  toggleFullScreen() : void;
  isFullScreen: boolean;
}

export interface CsvConfig {
  searchConfig: ColumnsSearchConfig;
  fileConfig: {
    uuid: string;
    step: string;
    filename: string;
  };
}

interface CsvExplorerViewState {
  loadingState: string;
  isLoading: boolean;
  rowCount: string;
  list: any;
}

class CsvExplorerView extends React.Component<CsvExplorerViewProps, CsvExplorerViewState> {
  state = {
    csvConfig: undefined,
    loadingState: undefined,
    isLoading: false,
    rowCount: undefined,
    list: new CsvTable(),
  };

  setRowCount = async (rowCount) => {
    if (this.state.rowCount === rowCount) {
      return;
    }

    await this.setState({ rowCount });
  }

  handleOnSearchChange = async (newSearchConfig: ColumnsSearchConfig) => {
    const csvConfig = Object.assign({},
      this.props.csvConfig,
      { searchConfig: newSearchConfig, }
    );

    this.props.onCsvConfigChange(csvConfig);
  }

  updateCurrentFileData = async (step, filename = '') => {
    const newFileConfig = { step, filename };

    const shouldUpdateSearchConfig =
      this.props.csvConfig.fileConfig.step !== step || this.props.csvConfig.fileConfig.filename !== filename;
    const newSearchConfig = shouldUpdateSearchConfig
      ? {}
      : this.props.csvConfig.searchConfig;

    const csvConfig = Object.assign(
      {},
      this.props.csvConfig,
      {
        fileConfig: newFileConfig,
        searchConfig: newSearchConfig,
      },
    );

    this.props.onCsvConfigChange(csvConfig);
  }

  getCurrentFileUUID() {
    const { csvConfig } = this.props;

    if (!csvConfig || !csvConfig.fileConfig) {
      return null;
    }

    const uuid = csvConfig.fileConfig ? csvConfig.fileConfig.uuid : '' ;
    return uuid;
  }

  componentDidMount() {
    // set focus to our search input when CTRL + f is pressed.
    window.addEventListener('keydown', this.focusOnSearchInput);
  }

  componentWillUnmount() {
    // remove listerner on page unmount.
    window.removeEventListener('keydown', this.focusOnSearchInput);
  }

  focusOnSearchInput = (event) => {
    const isCtrlF = ((event.ctrlKey || event.metaKey) && event.keyCode === 70);

    if (isCtrlF) { // prevent Browser search and force CSV search
      event.preventDefault();
      const element = document.querySelector('.gtfs-search-input') as HTMLElement;
      element.focus();
    }
  }

  handleCsvLifeCycle = async (readFileFn) => {
    const optimizedArray = new CsvTable();
    await this.setState({
      list: optimizedArray,
      loadingState: 'Parsing File ...',
      isLoading: true,
    });

    await readFileFn(optimizedArray);

    await this.setState({
      list: optimizedArray,
      loadingState: null,
      isLoading: false,
    });

    await this.setRowCount(optimizedArray.length);
  }

  processCsvUrl = async (url) => {
    const currentFileUUID = this.getCurrentFileUUID();

    await this.handleCsvLifeCycle(
      async (optimizedArray) => {
        const controller = new AbortController; // allows us to abort fetch request
        const signal = controller.signal;

        const response = await fetch(url, {
          signal, // bind controller to this fetch request using signal
          credentials: 'include',
        });

      /*
     Use string decoder to properly decode utf8 characters. Characters not in the basic ASCII take more
     than one byte.
     If the end of the batch cuts one of those characters, then we will yield weird characters.
     decoder will accumulate any "lost" utf8 character at the end of the batch and accumulate it for the next
     iteration.
      */

        const decoder = new TextDecoder('utf8');
        const reader = response.body.getReader(); // create a reader and lock it to the stream

        const contentLengthInBytes = response.headers.get('x-content-length');
        let bytesParsed = 0;

        let endOfPreviousBatch = '';
        const returnCarriages = ['\r', '\n', '\r\n'];
        let returnCarriage;

        let endOfFile = false;
        while (!endOfFile) {
          let reading = await reader.read();

          if (reading.done) { // When no more data needs to be consumed, break the reading
            endOfFile = true;
            break;
          }

          bytesParsed += reading.value.length;

          let valueDecoded = decoder.decode(reading.value);
          while (valueDecoded.length < 500000) { // while the decoded string is <5mb, don't parse it
            reading = await reader.read();

            if (reading.done) { // When no more data needs to be consumed, break the reading
              endOfFile = true;
              break;
            }

            bytesParsed += reading.value.length;
            valueDecoded += decoder.decode(reading.value);
          }

          if (!returnCarriage) { // Need to find which carriage return to use
            returnCarriage = returnCarriages.find(returnCarriage => RegExp(returnCarriage).test(valueDecoded));
          }

          if (endOfFile && !valueDecoded.endsWith(returnCarriage)) {
            // last batch must end with a carriage return or Papaparse wont parse correctly
            valueDecoded += returnCarriage;
          }

        /* tslint:disable max-line-length
         * If the last character in the decoded characters is not a return carriage, we must set aside the last characters
         * and prepend them to the following decoded characters.
         * Example:
         *  First batch of chars decoded:
         *    row1_field1, row1_field2, row1_field3
         *    row2_field1
         *
         * Second batch of chars decoded:
         *    row2_field2, row2_field3
         *    row3_field1, row3_field2, row3_field3
         *
         * In a case like above, row2 has been split between two batches of encoding. Thus we need to make sure that row2
         * is pre-appended to the second batch so that PapaParse can properly parse the rows.
         */
          const lastIndexOf = valueDecoded.lastIndexOf(returnCarriage);
          const currentBatch = endOfPreviousBatch + valueDecoded.substring(0, lastIndexOf);
          endOfPreviousBatch = valueDecoded.substring(lastIndexOf);

          await new Promise((resolve) => {
            this.createParser(currentBatch, {
              shouldAbort: () => {
                const fileHasChanged = this.getCurrentFileUUID() !== currentFileUUID;
                if (fileHasChanged) {
                  controller.abort();
                }

                return fileHasChanged;
              },
              onStep: (row) => {
                optimizedArray.push(row);
              },
              onComplete: () => {
                const percentParsed = Math.round(bytesParsed / parseInt(contentLengthInBytes, 10) * 100);
                this.setState({
                  loadingState: `Downloading File: ${percentParsed}%`,
                });

                resolve();
              },
            });
          });
        }

        reader.releaseLock();
      });
  }

  processCsvFile = async (file) => {
    const currentFileUUID = this.getCurrentFileUUID();

    this.handleCsvLifeCycle((rows) => {
      return new Promise((resolve) => {
        this.createParser(file, {
          shouldAbort: () => { return this.getCurrentFileUUID() !== currentFileUUID; },
          onStep: (row) => {
            rows.push(row);
          },
          onComplete:  () => {
            this.setState({
              loadingState: 'Parsing File: 100%',
            });

            resolve();
          },
        });
      });
    });
  }

  createParser(file, { onComplete, onStep, shouldAbort }) {
    return Papa.parse(file, {
      worker: true,
      skipEmptyLines: true,
      chunk: (result, parser) => {
        const { data: rows } = result;

        if (shouldAbort()) {
          parser.abort();
        }

        for (const row of rows) {
          onStep(row);
        }

        return true;
      },
      complete: onComplete,
    });
  }

  onFileSelected = async (step, filename) => {
    await this.updateCurrentFileData(step, filename);

    const currentFeedCode = this.props.feed.feed_code;

    const gtfsUrl = `${process.env.UPDATE_STATIC_DATA_URL}/gtfs`;
    const url = `${gtfsUrl}/${currentFeedCode}/${step}/${filename}`;
    await this.processCsvUrl(url);
  }

  readFileAndProcessFile = async (file) => {
    const shouldProcessFile = file && file.size > 0;
    if (!shouldProcessFile) {
      this.setState({
        loadingState: 'File Unavailable',
      });

      return;
    }

    await this.updateCurrentFileData('imported-file');
    await this.processCsvFile(file);
  }

  onDropFile = async (acceptedFiles) => {
    if (acceptedFiles.length !== 0) {
      await this.readFileAndProcessFile(acceptedFiles[0]);
    }
  }

  onFileImport = async (event) => {
    const file = event.target.files[0];
    if (file) {
      await this.readFileAndProcessFile(event.target.files[0]);
    }
  }

  render() {
    const { csvConfig = {} as CsvConfig, isFullScreen, toggleFullScreen } = this.props;
    const { isLoading, loadingState, list, rowCount } = this.state;
    const initialFile : any = csvConfig.fileConfig || {};
    const searchConfig : any = csvConfig.searchConfig || {};

    return (
      <div className="csv-explorer-wrapper">
        <div className="csv-control-wrapper">
          <div className="csv-control-group">
            <div className="csv-control">
              <CsvExplorerFullScreenButton isFullScreen={isFullScreen} onClick={toggleFullScreen} />
            </div>
            <div className="csv-control">
              <CsvFileSystemButton onFileImported={this.onFileImport}/>
            </div>
            <div className="csv-control">
              <SelectGtfsFileDropdowns
                onFileSelected={this.onFileSelected}
                initialStep={initialFile.step}
                initialFile={initialFile.filename}
              />
            </div>
            <div className="csv-control">
              {`${rowCount} rows`}
            </div>
          </div>
          <div className="csv-control-group">
            <div className="csv-control">
              <SearchGtfsTableInput
                openDropdownOnHover={true}
                headerCells={list.headers}
                searchConfig={searchConfig}
                onChange={this.handleOnSearchChange}
              />
            </div>
          </div>
        </div>
        <div className="csv-table-wrapper">
          <CsvFilterView
            key={this.getCurrentFileUUID()}
            list={list}
            listLength={list.length}
            searchConfig={searchConfig}
            updateRowCount={this.setRowCount}
          >
            {(filteredList, isCellHighlighted, filterNotice) => (
              <CsvSortedView list={filteredList}>
                 {(sortedList , sortConfig, toggleSort, sortNotice) => (
                   <CsvDropFileZone onDropFile={this.onDropFile}>
                    {(dropzoneActive) => { // tslint:disable-line
                      const isEmpty = !!initialFile.filename && list.length === 0;

                      const overlayFeedBack = dropzoneActive ? <div className="overlay">Drop files...</div>
                        : isLoading ? <div className="overlay">{loadingState}</div>
                        : filterNotice ? <div className="overlay">{filterNotice}</div>
                        : sortNotice ? <div className="overlay">{sortNotice}</div>
                        : isEmpty ? <div className="overlay">File is empty</div>
                        : null;

                      return (
                        <React.Fragment>
                          {overlayFeedBack}
                          <CsvTableView
                            headers={sortedList.headers}
                            isCellHighlighted={isCellHighlighted}
                            hideColumns={searchConfig.hideColumns || []}
                            rowsLength={sortedList.length}
                            rowGetter={sortedList.getRow}
                            minColumnWidths={list.minColumnWidths}
                            sortConfig={sortConfig}
                            toggleSort={toggleSort}
                          />
                        </React.Fragment>
                      );
                    }}
                  </CsvDropFileZone>
                 )}
              </CsvSortedView>
            )}
          </CsvFilterView>
        </div>
      </div>
    );
  }
}

export default withStore<CsvExplorerViewProps>(CsvExplorerView);
