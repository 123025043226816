import React from 'react';
import { observer } from 'mobx-react';
import { isEqual } from 'lodash';

import store from '@stores';
import { withStore } from '@stores/withStore';
import { Feed } from '@stores/transit';
import { STORAGE_TYPES, ENVIRONMENTS } from '@constants';
import StorageManager from '@utils/StorageManager';
import SideMenu from './components/SideMenu/SideMenu';
import JsonExtensionUI from './components/JsonExtensionUI/JsonExtensionUI';

interface JsonExtensionPageProps {
  store: typeof store;
  feed: Feed;
  environment: ENVIRONMENTS;
}

interface JsonExtensionPageState {
  isGettingFeedJsonExtension: boolean;
}

class JsonExtensionPage extends React.Component<JsonExtensionPageProps, JsonExtensionPageState> {
  localStorage = new StorageManager(STORAGE_TYPES.LOCAL, 'jsonExtension');

  constructor(props) {
    super(props);
    this.state = {
      isGettingFeedJsonExtension: true,
    };
  }

  async componentDidMount() {
    await this.getJsonExtensionForCurrentFeed();
  }

  async componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.feed, this.props.feed) || prevProps.environment !== this.props.environment) {
      this.setState({
        isGettingFeedJsonExtension: true,
      });

      await this.getJsonExtensionForCurrentFeed();
    }
  }

  async getJsonExtensionForCurrentFeed() {
    const { feed, store, environment } = this.props;
    const currentFeedCode = feed.feed_code;
    const currentEnvironment = environment;

    let jsonExtension;
    let shouldUseLocalJsonExtension = false;

    const jsonExtensionFromLocalStorage = this.localStorage.get(`${currentEnvironment}-${currentFeedCode}`);
    const jsonExtensionFromUsd = await store.pages.gtfs.jsonExtension.getJsonExtensionOfFeed(currentFeedCode, currentEnvironment); // tslint:disable-line max-line-length

    if (String(feed.feed_code) !== String(this.props.feed.feed_code)) { // handle race condition;
      return;
    }

    if (jsonExtensionFromLocalStorage && !isEqual(jsonExtensionFromLocalStorage, jsonExtensionFromUsd)) {
      shouldUseLocalJsonExtension = window.confirm('Recover your JSON Extension from the last time you edited?');
    }

    if (shouldUseLocalJsonExtension) {
      jsonExtension = jsonExtensionFromLocalStorage;
    } else {
      jsonExtension = jsonExtensionFromUsd;
      this.localStorage.remove(`${currentEnvironment}-${currentFeedCode}`);
    }

    store.pages.gtfs.jsonExtension.setDefaultJsonExtension(jsonExtension);
    this.setState({
      isGettingFeedJsonExtension: false,
    });
  }

  // --------------------------- //
  //     RENDERING FUNCTIONS     //
  // --------------------------- //

  renderLoading() {
    return (
      <div>
        loading
      </div>
    );
  }

  renderTransitPage() {
    const { feed: { feed_code } } = this.props;
    return (
      <div className="subpage-container">
        <SideMenu/>
        <JsonExtensionUI feedCode={String(feed_code)}/>
      </div>
    );
  }

  renderLoadingOrTransitPage() {
    return this.state.isGettingFeedJsonExtension
      ? this.renderLoading()
      : this.renderTransitPage();
  }

  render = this.renderLoadingOrTransitPage;
}

export default withStore(observer(JsonExtensionPage));
