import http from './lib/http';

const USD_API_GTFS = `${process.env.UPDATE_STATIC_DATA_URL}/gtfs`;

export default {
  /**
  * GET complete list of tools grouped by respectiive step;
  */
  getGtfsFilenameByStepOfFeedCode: async (feedCode: string): Promise<any> => {
    const { status , data } = await http.authenticatedGet(`${USD_API_GTFS}/${feedCode}/filenames`);

    if (status === 200 && data) {
      return data;
    }
  },

  getPathwaysDataForFeed: async (feedId: number | string): Promise<any> => {
    const url = `${USD_API_GTFS}/${feedId}/cache/metadata/stationMetadataByStationStableId`;

    const response = await http.authenticatedGet(url);
    return response.data;
  },
};
