import React from 'react';

import store from '@stores';
import { withStore } from '@stores/withStore';
import DropDown from '@components/Dropdown/Dropdown';

import 'react-virtualized/styles.css';
import '../CsvExplorerPage.scss';

interface SelectGtfsFileDropdownsProp {
  store: typeof store;
  hideFilenameDropdown?: boolean;
  initialStep?: string;
  initialFile?: string;
  onFileSelected(step: string, filename: string) : any;
}

type FileByStepEntries = [string, string[]][];
interface SelectGtfsFileDropdownsState {
  fileByStepEntries: FileByStepEntries;
  fileNames: string[];
  stepIndex: number;
  filenameIndex: number;
}

class SelectGtfsFileDropdowns extends React.Component<SelectGtfsFileDropdownsProp, SelectGtfsFileDropdownsState> {

  constructor(props) {
    super(props);

    this.state = {
      fileByStepEntries: [],
      fileNames: [],
      stepIndex: 0,
      filenameIndex: 0,
    };
  }

  async componentDidMount() {
    const { initialFile, initialStep, store } = this.props;

    const currentFeedCode = store.pages.gtfs.getCurrentFeedCode();
    const fileByStepEntries = await store.api.gtfs.getGtfsFilenameByStepOfFeedCode(currentFeedCode);

    await this.setState({ fileByStepEntries });

    let stepIndex = initialStep
      ? fileByStepEntries.findIndex(col => col[0] === initialStep)
      : fileByStepEntries.findIndex(col => col[1].length > 0);
    if (stepIndex < 0) {
      stepIndex = 0;
    }

    const filenameIndex = initialFile
      ? fileByStepEntries[stepIndex][1].indexOf(initialFile)
      : 0;

    await this.setState({
      filenameIndex,
      stepIndex,
    });

    this.onStepChange(stepIndex);
  }

  onStepChange = (stepIndex: number = 0) => {
    const {
      fileByStepEntries,
      filenameIndex: prevFilenameIndex,
      stepIndex: prevStepIndex,
    } = this.state;

    const fileNames = fileByStepEntries[stepIndex][1];

    // make sure that we auto-select the same filename at the next selected step.
    const prevFilename = fileByStepEntries[prevStepIndex][1][prevFilenameIndex];
    let filenameIndex = prevFilename && fileByStepEntries[stepIndex][1].indexOf(prevFilename);
    if (filenameIndex < 0) {
      filenameIndex = 0;
    }

    this.setState({
      fileNames,
      stepIndex,
    });

    this.onFilenameChange(filenameIndex, stepIndex);
  }

  onFilenameChange = (filenameIndex: number, stepIndex?: number) => {
    const {
      fileByStepEntries,
      stepIndex: prevStepIndex,
    } = this.state;

    const step = stepIndex !== undefined
      ? fileByStepEntries[stepIndex][0]
      : fileByStepEntries[prevStepIndex][0];

    const filename = stepIndex !== undefined
      ? fileByStepEntries[stepIndex][1][filenameIndex]
      : fileByStepEntries[prevStepIndex][1][filenameIndex];

    this.setState({ filenameIndex });
    this.props.onFileSelected(step, filename);
  }

  renderFilenameDropdown() {
    const { fileNames, filenameIndex } = this.state;
    const { hideFilenameDropdown } = this.props;
    if (hideFilenameDropdown) {
      return;
    }

    const filenamesDropdownItems = fileNames.map(filename => ({
      isDisabled: false,
      text: filename,
    }));

    return (
      <DropDown
        selectedIndex={filenameIndex}
        onChange={this.onFilenameChange}
        items={filenamesDropdownItems}
      />
    );
  }

  render() {
    const { fileByStepEntries, stepIndex } = this.state;

    const stepsDropdownItems = fileByStepEntries.map(column => ({
      isDisabled: !column[1].length,
      text: column[0],
    }));

    return (
      <div className="select-gtfs-file-dropdowns-wrapper">
        <DropDown
          selectedIndex={stepIndex}
          onChange={this.onStepChange}
          items={stepsDropdownItems}
        />

        {this.renderFilenameDropdown()}
      </div>
    );
  }
}

export default withStore<SelectGtfsFileDropdownsProp>(SelectGtfsFileDropdowns);
