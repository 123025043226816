import { AxiosResponse } from 'axios';
import { action, observable, computed, runInAction } from 'mobx';
import { STORAGE_TYPES, ENVIRONMENTS } from '@constants';
import StorageManager from '@utils/StorageManager';
import api from '@stores/api';

import Step from './lib/Step';
import Tool from './lib/Tool';

import type { Extension } from '@application/stores/api/api.types';

// Temporary
interface ToolsByStepInterface {
  [key: string] : Step;
}

interface JsonExtensionByFeedCodeInterface {
  [feedCode: string] : Extension;
}

const localStorage = new StorageManager(STORAGE_TYPES.LOCAL, 'jsonExtension');

class JsonExtensionBuilderStore {
  @observable public jsonExtensionByFeedCode: JsonExtensionByFeedCodeInterface = {};
  @observable public toolsByStep: ToolsByStepInterface = {}; // all the tools that can be selected
  @observable public selectedToolList : Tool[] = []; // all the tools that are selected

  @computed public get steps() {
    let toolIndex = 1;
    return Object.entries(this.toolsByStep)
      .map(([stepName , stepConfig]) => {
        const tools = stepConfig.tools;
        const indexedTools = tools.map((tool) => {
          tool.index = toolIndex;
          toolIndex += 1;
          return tool;
        });

        return new Step(stepName, indexedTools);
      });
  }

  @computed public get selectedToolsNamesSet() : Set<string> {
    const selectedToolsNames: Set<string> = new Set();

    this.selectedToolList.forEach((tool) => {
      selectedToolsNames.add(tool.name);
    });

    return selectedToolsNames;
  }

  public deselectedToolsNamesSet(feedCode: string): Set<string> {
    const jsonExtension = this.jsonExtensionByFeedCode[feedCode];
    const deselectedToolsNames: Set<string> = new Set();

    for (const step of Object.values(jsonExtension)) {
      for (const toolName of Object.keys(step)) {
        const isToolNameSelected = this.selectedToolsNamesSet.has(toolName);
        if (!isToolNameSelected) {
          deselectedToolsNames.add(toolName);
        }
      }
    }

    return deselectedToolsNames;
  }

  public isToolSelected(toolName) {
    return this.selectedToolsNamesSet.has(toolName);
  }

  public isToolDeselected(feedCode, toolName) {
    return this.deselectedToolsNamesSet(feedCode).has(toolName);
  }

  public getValueOfToolNameOfFeedCode(feedCode: string, toolName: string) {
    const jsonExtension = this.jsonExtensionByFeedCode[feedCode];
    for (const step of Object.values(jsonExtension)) {
      if (typeof step[toolName] !== 'undefined') {
        return step[toolName];
      }
    }
  }

  @computed public get sortedToolList() : Tool[] {
    const tools = this.steps.reduce((acc, step) => {
      if (!step.tools) { return acc; }
      return acc.concat(step.tools);
    }, []);

    const sortedToolList = tools.sort((left, right) => left.index - right.index);
    return sortedToolList;
  }

   /**
    * Add / Remove tool from selectedToolList
    * @param tool
    */
  @action public toggleTool = (tool: Tool) => {
    const toolInList = this.selectedToolList.find(a => a.name === tool.name);
    if (!toolInList) {
      this.addTool(tool);
    } else {
      this.removeTool(tool);
    }
  }

  /**
   * Get JSON extension used for feed
   * Promise that resolves to the JSON Extension
   * @param feedCode feed code ( ex: 'STM', 'DCTATX' )
   */
  @action public async getJsonExtensionOfFeed(feedCode: string, environment: ENVIRONMENTS): Promise<any> {
    const getTools = await this.updateToolsByStep();
    const getJsonExtension = await api.feeds.getJsonExtensionForFeed({ feedCode, environment });

    const [jsonExtension] = await Promise.all([getJsonExtension, getTools]);

    runInAction(() => {
      this.jsonExtensionByFeedCode[feedCode] = jsonExtension;
    });

    return jsonExtension;
  }

   /**
    * Set selected Tool list to match JSON extension
    * @param jsonExtension
    */
  @action public setDefaultJsonExtension = (jsonExtension: any) => {
    this.selectedToolList.splice(0); // refresh selectedToolist, keep mobx observable.
    this.steps.forEach((step) => {

      if (!jsonExtension.hasOwnProperty(step.name)) { return; }
      const stepDefaults = jsonExtension[step.name];
      step.tools.forEach((tool) => {

        if (!stepDefaults.hasOwnProperty(tool.name)) { return; }
        const defaultValue = { [tool.name]: stepDefaults[tool.name] };
        this.addTool(tool, defaultValue);

      });

    });
  }

  @action public updateToolsByStep = async () => {
    const toolsByStep : any = await api.tools.getJsonExtensionToolsListByStep();

    runInAction(() => {
      this.toolsByStep = toolsByStep;
    });

    return this.toolsByStep;
  }

   /**
    * Build Json Extension with tools configuration
    * each key is the name of a tool, corresponding value is it's configuration.
    * @param toolsConfig
    * @returns { string } JsonExtension
    */

  public commitJsonExtension = async (options: {
    feedCode: string,
    toolValueByToolName: object,
    commitEnv: ENVIRONMENTS,
    commitTitle: string,
  }): Promise<AxiosResponse> => {

    const { feedCode, toolValueByToolName, commitEnv, commitTitle } = options;

    const jsonExtension = this.getJsonExtension(toolValueByToolName);
    const meta = this.getMetaDataOfFeedCode(feedCode);

    // append meta data to the jsonExtension
    Object.assign(jsonExtension, { meta });

    const config = {
      feedCode,
      jsonExtension,
      commitEnv,
      commitTitle,
    };

    const response = await api.feeds.saveJsonExtensionForFeed(config);
    if (response) {
      this.jsonExtensionByFeedCode[feedCode] = jsonExtension;
      // remove localStorage jsonExtension;
      localStorage.remove(`${commitEnv}-${feedCode}`);
    }

    return response;
  }

  /**
   * Will only upload the changes made to the notes
   */
  public commitNotes = async (feedCode: string, toolName?: string) => {
    const jsonExtension = this.jsonExtensionByFeedCode[feedCode];

    const response = await api.feeds.saveJsonExtensionForFeed({
      feedCode,
      jsonExtension,
      commitEnv: ENVIRONMENTS.PRODUCTION,
      commitTitle: 'update notes',
    });

    return !!response;
  }

  /**
   * Get Stringified Json Extension
   * @param listOfTools - currently selected tools
   * @returns { object } JsonExtension
   */
  public getJsonExtension = (listOfTools: object) => {
    return matchToolsWithStep(this.steps, listOfTools);
  }

  public getMetaDataOfFeedCode(feedCode: string) {
    const meta = this.jsonExtensionByFeedCode[feedCode]
              && this.jsonExtensionByFeedCode[feedCode].meta;

    return meta || {};
  }

  public getNotesOfFeedCodeAndToolName = (feedCode: string, toolName: string) : string => {
    const note = this.jsonExtensionByFeedCode[feedCode]
              && this.jsonExtensionByFeedCode[feedCode].meta
              && this.jsonExtensionByFeedCode[feedCode].meta.notes
              && this.jsonExtensionByFeedCode[feedCode].meta.notes[toolName];

    return note || '';
  }

  @action public setNoteOfToolOfFeedCode= (feedCode: string, toolName: string, notes: string) => {
    this.jsonExtensionByFeedCode[feedCode].meta = this.jsonExtensionByFeedCode[feedCode].meta || {};
    this.jsonExtensionByFeedCode[feedCode].meta.notes = this.jsonExtensionByFeedCode[feedCode].meta.notes || {};
    this.jsonExtensionByFeedCode[feedCode].meta.notes[toolName] = notes || '';
  }

  /**
   * Get Stringified Json Extension
   * @param listOfTools - currently selected tools
   * @returns { string } Stringified JsonExtension
   */
  public getStringifiedJsonExtension = (listOfTools: object) => {
    const jsonExtension = this.getJsonExtension(listOfTools);
    return JSON.stringify(jsonExtension, null, 2);
  }

   /**
    * Add tool to selectedToolList
    * @param tool
    */
  @action public addTool = (tool, defaults?) => {
    const continueIfNoDuplicate = this.selectedToolList
      .every(selectedTool => selectedTool.name !== tool.name);

    if (continueIfNoDuplicate || this.selectedToolList.length === 0) {
      const insertAtIndex = this.getToolPosition(tool);
      const newTool = new Tool(tool, defaults);
      this.selectedToolList.splice(insertAtIndex, 0, newTool);
    }
  }

   /**
    * remove tool to selectedToolList
    * @param tool
    */
  @action public removeTool = (tool) => {
    const removeAtIndex = this.getToolPosition(tool);
    if (tool.index === this.selectedToolList[removeAtIndex - 1].index) {
      this.selectedToolList.splice(removeAtIndex - 1, 1);
    }
  }

  private getToolPosition(tool) {
    const toolIndex = tool.index; // get the global index of the tool
    const indexToInsertBefore = this.selectedToolList
      .findIndex(selectedTool => selectedTool.index > toolIndex); // first tool with a greater index

    return indexToInsertBefore >= 0 ? indexToInsertBefore : this.selectedToolList.length;
  }
}

export default new JsonExtensionBuilderStore();

/**
  * Build Json Extension with tools configuration
  * each key is the name of a tool, corresponding value is it's configuration.
  * @param toolsConfig
  * @returns { string } JsonExtension
  */
function matchToolsWithStep(steps, toolConfigs) {
  return steps.reduce((acc: any, step) => {

    const toolsByStep = step.tools.reduce((acc, tool) => {
      return toolConfigs.hasOwnProperty(tool.name)
        ? Object.assign(acc, { [tool.name]: toolConfigs[tool.name] })
        : acc;
    }, {});

    return Object.keys(toolsByStep).length
      ? Object.assign(acc, { [step.name]: toolsByStep })
      : acc;

  }, {});
}
